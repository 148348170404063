


















































































































import { Vue, Component } from "vue-property-decorator";

@Component
export default class WalletFaqContent extends Vue {
  goToWallet() {
    window.open("/wallet", "_self");
  }
}
