















































import { Vue, Component } from "vue-property-decorator";

import Modal from "@/components/modals/Modal.vue";
import Identicon from "@/components/misc/Identicon.vue";
import { iUserAccountEncrypted } from "@/store/types";
import ChangePassword from "@/components/modals/AccountSettings/ChangePassword.vue";
import DeleteAccount from "@/components/modals/AccountSettings/DeleteAccount.vue";
import SaveKeys from "@/components/modals/AccountSettings/SaveKeys.vue";
@Component({
  components: {
    ChangePassword,
    Identicon,
    Modal,
  },
})
export default class AccountSettingsModal extends Vue {
  $refs!: {
    modal: Modal;
  };

  subComponent: any = null;

  get account(): iUserAccountEncrypted {
    return this.$store.getters["Accounts/account"];
  }
  open() {
    this.$refs.modal.open();
  }

  close() {
    this.$refs.modal.close();
  }

  clear() {
    this.subComponent = null;
  }

  changePassword() {
    this.subComponent = ChangePassword;
  }

  deleteAccount() {
    this.subComponent = DeleteAccount;
  }

  saveKeys() {
    this.subComponent = SaveKeys;
  }

  get hasVolatile() {
    return this.$store.state.volatileWallets.length > 0;
  }
}
