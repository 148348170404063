














































import "reflect-metadata";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import Modal from "./Modal.vue";
import { web3 } from "@/evm";
import ERC20Abi from "@openzeppelin/contracts/build/contracts/ERC20.json";
import ERC721Abi from "@openzeppelin/contracts/build/contracts/ERC721.json";
import Erc20Token from "@/js/Erc20Token";
import { TokenListToken } from "@/store/modules/assets/types";
import { ERC721TokenInput } from "@/store/modules/assets/modules/types";
import { WalletType } from "@/js/wallets/types";
import axios from "axios";
import ERC721Token from "@/js/ERC721Token";

@Component({
  components: {
    Modal,
  },
})
export default class AddERC721TokenModal extends Vue {
  tokenAddress = "";
  name = "";
  symbol = "";
  canAdd = false;
  err = "";

  @Watch("tokenAddress")
  async onAddressChange(val: string) {
    this.err = "";
    if (val === "") {
      this.clear();
      return;
    }
    await this.validateAddress(val);
  }

  async validateAddress(val: string) {
    if (val === "") {
      this.err = "";
      return false;
    }
    try {
      //@ts-ignore
      var tokenInst = new web3.eth.Contract(ERC721Abi.abi, val);
      let name = await tokenInst.methods.name().call();
      let symbol = await tokenInst.methods.symbol().call();

      this.symbol = symbol;
      this.name = name;

      this.canAdd = true;
      return true;
    } catch (e) {
      this.canAdd = false;
      this.symbol = "-";
      this.name = "-";
      this.err = "Invalid contract address.";
      return false;
    }
  }

  clear() {
    this.tokenAddress = "";
    this.canAdd = false;
    this.symbol = "-";
    this.name = "-";
    this.err = "";
  }

  async submit() {
    try {
      let data: ERC721TokenInput = {
        address: this.tokenAddress,
        name: this.name,
        symbol: this.symbol,
        chainId: this.$store.state.Assets.evmChainId,
      };

      let token: ERC721Token = await this.$store.dispatch(
        "Assets/ERC721/addCustom",
        data
      );

      this.$store.dispatch("Notifications/add", {
        title: "ERC721 Token Added",
        message: token.name,
      });
      this.close();
    } catch (e) {
      this.err = e.message;
      console.error(e);
    }
  }

  beforeClose() {
    this.clear();
  }

  open() {
    // @ts-ignore
    this.$refs.modal.open();
  }

  close() {
    // @ts-ignore
    this.$refs.modal.close();
  }

  async removeToken(token: ERC721Token) {
    await this.$store.dispatch("Assets/ERC721/removeCustom", token);
  }

  get networkTokens(): ERC721Token[] {
    return this.$store.getters["Assets/ERC721/networkContractsCustom"];
  }
}
