























import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import Modal from "@/components/modals/Modal.vue";
import AvaAsset from "@/js/AvaAsset";
import { bnToBig } from "@/helpers/helper";

@Component({
  components: {
    Modal,
  },
  filters: {
    bal(asset: AvaAsset) {
      return bnToBig(asset.amount, asset.denomination).toLocaleString();
    },
  },
})
export default class PrivateKey extends Vue {
  @Prop() assets!: AvaAsset[];
  @Prop({ default: () => [] }) disabledIds!: string[]; // asset id | if nft the utxo id

  open(): void {
    let modal = this.$refs.modal as Modal;
    modal.open();
  }

  close() {
    let modal = this.$refs.modal as Modal;
    modal.close();
  }

  select(asset: AvaAsset) {
    if (asset.amount.isZero()) return;
    if (this.isDisabled(asset)) return;

    this.close();
    this.$emit("select", asset);
  }

  isDisabled(asset: AvaAsset): boolean {
    if (this.disabledIds.includes(asset.id)) return true;
    return false;
  }
}
