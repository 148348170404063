









































import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import Modal from "@/components/modals/Modal.vue";
import CopyText from "@/components/misc/CopyText.vue";

@Component({
  components: {
    Modal,
    CopyText,
  },
})
export default class ConfirmLogout extends Vue {
  isLoading = false;
  @Prop({ default: "" }) phrase!: string;

  open(): void {
    let modal = this.$refs.modal as Modal;
    modal.open();
  }

  close(): void {
    let modal = this.$refs.modal as Modal;
    modal.close();
  }

  async submit() {
    this.isLoading = true;
    await this.$store.dispatch("logout");
    await this.$store.dispatch("Notifications/add", {
      title: "Logout",
      message: "You have successfully logged out of your wallet.",
    });
    this.isLoading = false;
    this.close();
  }
}
