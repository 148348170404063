




































import "reflect-metadata";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import Modal from "./Modal.vue";
import { web3 } from "@/evm";
import ERC20Abi from "@openzeppelin/contracts/build/contracts/ERC20.json";
import Erc20Token from "@/js/Erc20Token";
import { TokenListToken } from "@/store/modules/assets/types";

@Component({
  components: {
    Modal,
  },
})
export default class AddERC20TokenModal extends Vue {
  tokenAddress = "";
  name = "";
  symbol = "";
  denomination = 1;
  canAdd = false;
  err = "";
  @Watch("tokenAddress")
  async onAddressChange(val: string) {
    this.err = "";
    if (val === "") {
      this.clear();
      return;
    }
    await this.validateAddress(val);
  }

  async validateAddress(val: string) {
    if (val === "") {
      this.err = "";
      return false;
    }
    try {
      //@ts-ignore
      var tokenInst = new web3.eth.Contract(ERC20Abi.abi, val);
      let name = await tokenInst.methods.name().call();
      let symbol = await tokenInst.methods.symbol().call();
      let decimals = await tokenInst.methods.decimals().call();

      this.symbol = symbol;
      this.denomination = decimals;
      this.name = name;

      this.canAdd = true;
      return true;
    } catch (e) {
      this.canAdd = false;
      this.symbol = "-";
      this.denomination = 0;
      this.name = "-";
      this.err = "Invalid contract address.";
      return false;
    }
  }

  clear() {
    this.tokenAddress = "";
    this.canAdd = false;
    this.symbol = "-";
    this.denomination = 0;
    this.name = "-";
    this.err = "";
  }

  async submit() {
    try {
      let data: TokenListToken = {
        address: this.tokenAddress,
        name: this.name,
        symbol: this.symbol,
        decimals: this.denomination,
        chainId: this.$store.state.Assets.evmChainId,
        logoURI: "",
      };

      let token: Erc20Token = await this.$store.dispatch(
        "Assets/addCustomErc20Token",
        data
      );

      this.$store.dispatch("Notifications/add", {
        title: "ERC20 Token Added",
        message: token.data.name,
      });
      this.close();
    } catch (e) {
      this.err = e.message;
      console.error(e);
    }
  }

  beforeClose() {
    this.clear();
  }

  open() {
    // @ts-ignore
    this.$refs.modal.open();
  }

  close() {
    // @ts-ignore
    this.$refs.modal.close();
  }
}
